import '../resources/Menupoint.css';

import React from 'react';
import { Link } from 'react-router-dom';

interface IMenupointProps {
  title: string;
  route: string;
  activePoint?: "Startseite" | "Team" | "Kontakt" | string;
}

export function Menupoint(props: IMenupointProps) {
  const { title, route, activePoint } = props;
  return (
    <Link to={"/" + route}>
      <div
        className={
          activePoint === title ? "header-menupoint active" : "header-menupoint"
        }
      >
        {title}
      </div>
    </Link>
  );
}
export default Menupoint;
