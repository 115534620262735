import React from 'react';

import Breadcrumb from '../components/Breadcrumb/components/Breadcrumb';
import Footer from '../components/Footer/components/Footer';
import Header from '../components/Header/components/Header';
import InformationFooter from '../components/InformationFooter/components/InformationFooter';

export function Erstinformation() {
  return (
    <div className="page-wrapper">
      <Header />
      <Breadcrumb route="Erstinformation" />
      <div className="content">
        <h3>
          Information nach §11 Versicherungsvermittlungsverordnung – VersVermV
        </h3>
        <p>
          <h4>Firma und Anschrift</h4>
          Versicherungsmaklerbüro Kraus, Pfarrer-Plecher-Str. 31, 95676 Wiesau
          <br />
          Telefon: 09634/2005, Telefax: 09634/8214
          <br />
          E-Mail: dorothea.kraus@t-online.de
          <br />
          <br />
          Inhaber: Dorothea Kraus
          <br />
          Steuernummer: 254/240/10661
          <br />
          <h4>Status des Vermittlers nach Gewerbeordnung</h4>
          Versicherungsmakler mit Erlaubnis nach § 34d Abs. 1 der Gewerbeordnung
          <br />
          Registrierungsstelle: IHK für Münhcen und Oberbayern,
          Max-Joseph-Straße 2, 80333 München
          <br />
          Telefon 089/5116-0, Telefax: 089/5116-666
          <br />
          Internet: www.muenchen.ihk.de
          <br />
          Registriernummer: D-AIGA-9OJWP-16
          <br />
          <br />
          Bei Interesse können Sie die Angaben bei der Registerstelle
          überprüfen:
          <br />
          Deutscher Industrie- und Handelskammertag e.V., Breite Straße 29,
          10178 Berlin
          <br />
          Tel.: 0180-500-585-0
          <br />
          Internet: www.vermittlerregister.info
          <br />
          <h4>Beteiligungen</h4>
          Der Vermittler hält keine direkte oder indirekte Beteiligung von über
          10% an den Stimmrechten oder am Kapital eines
          Versicherungsunternehmens. Umgekehrt hält auch kein
          Versicherungsunternehmen oder Mutterunternehmen eines
          Versicherungsunternehmens eine direkte oder indirekte Beteiligung von
          über 10% an den Stimmrechten oder am Kapital unseres Unternehmens.
          <h4>Schlichtungs- und Beschwerdestellen:</h4>
          zur außergerichtlichen Beteiligung von Streitigkeiten oder Beschwerden
          stehen ihnen folgende Beschwerdestellen zur Verfügung:
          Versicherungsombudsmann e.V.
          <br />
          <br />
          Prof. Dr. Günter Hirsch
          <br />
          Postfach 08 06 32
          <br />
          10006 Berlin
          <br />
          (weitere Informationen unter: www.versicherungsombudsmann.de)
          <br />
          <br />
          Ombudsmann private Kranken- und Pflegeversicherung
          <br />
          Dr. Helmut Müller
          <br />
          Postfach 06 02 22
          <br />
          10052 Berlin
          <br />
          <br />
          (weitere Informationen unter: www.pkw-ombudsmann.de)
          <br />
          Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin)
          <br />
          Graurheindorfer Str. 108
          <br />
          53117 Bonn
          <br />
          (weitere Informationen unter: www.bafin.de) [Stichwort: Ombudsleute]
        </p>
      </div>
      <InformationFooter />
      <Footer />
    </div>
  );
}

export default Erstinformation;
