import '../resources/Footer.css';

import React from 'react';


export function Footer() {
  return (
    <div className="footer flex justify-content-space">
      <div className="footer-container">
        &copy; 2019 Versicherungsmaklerbüro Kraus
      </div>
      <div className="footer-container">
        Konzeption und Realisierung:
        <a href="https://inter-media.eu"> InterMedia digital soluitons.</a>
      </div>
    </div>
  );
}

export default Footer;
