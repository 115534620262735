import './Pages.css';

import React from 'react';

import Breadcrumb from '../components/Breadcrumb/components/Breadcrumb';
import Footer from '../components/Footer/components/Footer';
import Header from '../components/Header/components/Header';
import InformationFooter from '../components/InformationFooter/components/InformationFooter';

export function Impressum() {
  return (
    <div className="page-wrapper">
      <Header />
      <Breadcrumb route="Impressum" />
      <div className="content">
        <h2>Angaben gemäß § 5 TMG:</h2>
        <p>
          Dorothea Kraus
          <br />
          Versicherungsmaklerbüro
          <br />
          Pfarrer-Plecher-Straße 31
          <br />
          95676 Wiesau
          <br />
        </p>
        <h2>Kontakt</h2>
        Telefon: 09634 / 2005
        <br />
        Telefax: 09634 / 8214
        <br />
        E-Mail: dorothea.kraus@t-online.de
        <br />
        <h2>Aufsichtsbehörde</h2>
        <p>
          IHK für München und Oberbayern
          <br />
          Max-Joseph-Straße 2<br />
          80333 München
          <br />
          Web: www.IHK-Muenchen.de
          <br />
          <br />
          Name und Sitz der Gesellschaft:
          <br />
          R+V Versicherung AG
          <br />
          Raiffeisenplatz 1<br />
          65189 Wiesbaden
          <br />
          <br />
          Geltungsraum der Versicherung:
          <br />
          Deutschland
          <br />
        </p>
      </div>
      <InformationFooter />
      <Footer />
    </div>
  );
}
export default Impressum;
