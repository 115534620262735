import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Datenschutz from "./pages/Datenschutz";
import Erstinformation from "./pages/Erstinformation";
import Impressum from "./pages/Impressum";
import Kontakt from "./pages/Kontakt";
import React from "react";
import Startpage from "./pages/Startpage";
import Team from "./pages/Team";

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Startpage />
        </Route>
        <Route exact path="/Team">
          <Team />
        </Route>
        <Route exact path="/Kontakt">
          <Kontakt />
        </Route>
        <Route exact path="/Impressum">
          <Impressum />
        </Route>
        <Route exact path="/Datenschutz">
          <Datenschutz />
        </Route>
        <Route exact path="/Erstinformation">
          <Erstinformation />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
