import './Pages.css';

import React from 'react';
import { Slide } from 'react-slideshow-image';

import imgGruppe from '../assets/img/gruppe.jpg';
import imgSlide1 from '../assets/slider/slider-1.jpg';
import imgSlide2 from '../assets/slider/slider-2.jpg';
import imgSlide3 from '../assets/slider/slider-3.jpg';
import Footer from '../components/Footer/components/Footer';
import { Header } from '../components/Header/components/Header';
import InformationFooter from '../components/InformationFooter/components/InformationFooter';

export function Startpage() {
  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: false
  };

  return (
    <div className="page-wrapper">
      <Header activePoint="Startseite" />
      <div className="slider-wrapper">
        <Slide {...properties}>
          <div className="each-slide">
            <div style={{ backgroundImage: `url(${imgSlide1})` }}></div>
          </div>
          <div className="each-slide">
            <div style={{ backgroundImage: `url(${imgSlide2})` }}></div>
          </div>
          <div className="each-slide">
            <div style={{ backgroundImage: `url(${imgSlide3})` }}></div>
          </div>
        </Slide>
      </div>
      <div className="content flex justify-content-space">
        <div className="content-inner">
          <h2>Versicherungsmaklerbüro Kraus</h2>
          <p>
            Herzlich Willkommen im Versicherungsmaklerbüro Kraus, Ihrem
            Versicherungsmakler in der nördlichen Oberpfalz. Wir bieten für Sie
            als Privat- oder Geschäftskunden einen perfekt auf Ihren Bedarf
            zugeschnittenen Versicherungsrundumschutz an.
          </p>
          <p>
            Durch unsere langjährige Erfahrung und den Einsatz unabhängiger
            Vergleichsprogramme ist es uns möglich, Ihnen optimal auf Ihre
            Bedürfnisse zugeschnittene Versicherungslösungen zu offerieren.
          </p>
          <p>
            Die Zufriedenheit und das Vertrauen unserer Kunden steht für uns an
            erster Stelle.
          </p>
          <p>
            Auf ein persönliches Gespräch mit Ihnen in unserem Büro würden wir
            uns freuen. Sie können uns aber auch jederzeit gerne telefonisch
            oder per E-Mailkontaktieren. Ihr Team des Versicherungsmaklerbüros
            Kraus in Wiesau
          </p>
        </div>
        <div className="content-inner img-gruppe">
          <img src={imgGruppe} alt="Team" />
        </div>
      </div>
      <InformationFooter />
      <Footer />
    </div>
  );
}
export default Startpage;
