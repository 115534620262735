import './Pages.css';

import React from 'react';

import Breadcrumb from '../components/Breadcrumb/components/Breadcrumb';
import Footer from '../components/Footer/components/Footer';
import Header from '../components/Header/components/Header';
import InformationFooter from '../components/InformationFooter/components/InformationFooter';

export function Kontakt() {
  return (
    <div className="page-wrapper">
      <Header activePoint="Kontakt" />
      <Breadcrumb route="Kontakt" />
      <div className="content">
        <h2>Nehmen Sie Kontakt mit uns auf</h2>
        <p>
          E-Mail: dorothea.kraus@t-online.de
          <br />
          Telefon: 09634 / 2005 <br />
          Fax: 09634 / 8214 <br />
        </p>
        <h2>oder besuchen Sie uns</h2>
        <p>
          Versicherungsmaklerbüro Kraus
          <br />
          Pfarrer-Plecher-Straße 31
          <br />
          95676 Wiesau
          <br />
        </p>
      </div>
      <InformationFooter />
      <Footer />
    </div>
  );
}

export default Kontakt;
