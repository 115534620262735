import React from 'react';

import Breadcrumb from '../components/Breadcrumb/components/Breadcrumb';
import Footer from '../components/Footer/components/Footer';
import Header from '../components/Header/components/Header';
import InformationFooter from '../components/InformationFooter/components/InformationFooter';

export function Datenschutz() {
  return (
    <div className="page-wrapper">
      <Header />
      <Breadcrumb route="Datenschutz" />
      <div className="content justify">
        <h2>Haftung für Inhalte</h2>
        <p>
          Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
          auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
          §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
          verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
          überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
          Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
          Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
          hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
          Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
          Inhalte umgehend entfernen.
        </p>
        <h2>Haftung für Links </h2>{" "}
        <p>
          Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
          Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
          fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
          verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
          Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werden wir derartige Links
          umgehend entfernen.
        </p>
        <h2>Urheberrecht</h2>
        <p>
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
          sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
          wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
          Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf
          eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
          werden wir derartige Inhalte umgehend entfernen.
        </p>
        <h2>Datenschutz</h2>
        <p>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung. Die Nutzung unserer Webseite ist in der Regel
          ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
          personenbezogene Daten (beispielsweise Name, Anschrift oder
          E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
          auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
          Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin, dass
          die Datenübertragung im Internet (z.B. bei der Kommunikation per
          E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der
          Daten vor dem Zugriff durch Dritte ist nicht möglich.
        </p>
        <h2> Cookies</h2>{" "}
        <p>
          Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
          richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
          Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
          sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
          Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der
          von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie
          werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies
          bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese
          Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
          wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie
          über das Setzen von Cookies informiert werden und Cookies nur im
          Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder
          generell ausschließen sowie das automatische Löschen der Cookies beim
          Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies
          kann die Funktionalität dieser Website eingeschränkt sein.{" "}
        </p>
        <h2>Server-Log-Files</h2>
        <p>
          Der Provider der Seiten erhebt und speichert automatisch Informationen
          in so genannten Server-Log Files, die Ihr Browser automatisch an uns
          übermittelt. Dies sind: Browsertyp und Browserversion verwendetes
          Betriebssystem Referrer URL Hostname des zugreifenden Rechners Uhrzeit
          der Serveranfrage Diese Daten sind nicht bestimmten Personen
          zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen
          wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich
          zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige
          Nutzung bekannt werden.
        </p>
        <h2>Kontaktformular</h2>
        <p>
          {" "}
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
          Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
          nicht ohne Ihre Einwilligung weiter.{" "}
        </p>
        <h2>Datenschutzerklärung für die Nutzung von Google Analytics</h2>
        <p>
          Diese Website nutzt Funktionen des Webanalysedienstes Google
          Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway
          Mountain View, CA 94043, USA. Google Analytics verwendet so genannte
          „Cookies“. Das sind Textdateien, die auf Ihrem Computer gespeichert
          werden und die eine Analyse der Benutzung der Website durch Sie
          ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre
          Benutzung dieser Website werden in der Regel an einen Server von
          Google in den USA übertragen und dort gespeichert. Mehr Informationen
          zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
          Datenschutzerklärung von Google:
          https://support.google.com/analytics/answer/6004245?hl=de Browser
          Plugin Sie können die Speicherung der Cookies durch eine entsprechende
          Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
          darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können. Sie
          können darüber hinaus die Erfassung der durch den Cookie erzeugten und
          auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse)
          an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
          indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
          herunterladen und installieren:
          https://tools.google.com/dlpage/gaoptout?hl=de Widerspruch gegen
          Datenerfassung Sie können die Erfassung Ihrer Daten durch Google
          Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird
          ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei
          zukünftigen Besuchen dieser Website verhindert: Google Analytics
          deaktivieren Auftragsdatenverarbeitung Wir haben mit Google einen
          Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die
          strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung
          von Google Analytics vollständig um. IPAnonymisierung Wir nutzen die
          Funktion „Aktivierung der IP-Anonymisierung“ auf dieser Webseite.
          Dadurch wird Ihre IP-Adresse von Google jedoch innerhalb von
          Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
          des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
          in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
          in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers
          dieser Website wird Google diese Informationen benutzen, um Ihre
          Nutzung der Website auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen
          gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google
          Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
          anderen Daten von Google zusammengeführt.
        </p>
        <h2>SSL-Verschlüsselung</h2>
        <p>
          Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen, die
          Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
          verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des
          Browsers von „http://“ auf „https://“ wechselt und an dem
          Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL Verschlüsselung
          aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
          Dritten mitgelesen werden.
        </p>
      </div>
      <InformationFooter />
      <Footer />
    </div>
  );
}

export default Datenschutz;
