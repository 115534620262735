import React from 'react';

import imgDorothea from '../assets/img/dorothea-kraus.jpg';
import imgHeinrich from '../assets/img/heinrich-kraus.jpg';
import imgStefan from '../assets/img/stefan-eckl.jpg';
import Breadcrumb from '../components/Breadcrumb/components/Breadcrumb';
import Footer from '../components/Footer/components/Footer';
import Header from '../components/Header/components/Header';
import InformationFooter from '../components/InformationFooter/components/InformationFooter';
import { Member } from '../components/Member/components/Member';

export function Team() {
  return (
    <div className="page-wrapper">
      <Header activePoint="Team" />
      <Breadcrumb route="Team" />
      <div className="content flex justify-content-space align-items">
        <Member
          name="Dorothea Kraus"
          email="Dorothea.kraus(at)t-online.de"
          emailLink="dorothea.kraus@t-online.de"
          image={imgDorothea}
        />
        <Member
          name="Stefan Eckl"
          email="Stefan.eckl(at)vmb-kraus.de"
          emailLink="stefan.eckl@vmb-kraus.de"
          image={imgStefan}
        />
        <Member
          name="Heinrich Kraus"
          email="Heinrich.Kraus(at)vmb-kraus.de"
          emailLink="heinrich.kraus@vmb-kraus.de"
          image={imgHeinrich}
        />
      </div>
      <InformationFooter />
      <Footer />
    </div>
  );
}

export default Team;
