import '../resources/Member.css';

import React from 'react';

interface IMemberProps {
    name: string;
    image: any;
    email: any;
    emailLink: any;
}

export function Member(props: IMemberProps) {
    const { name, image, email, emailLink } = props;
    return (
        <div className="team-member">
            <div className="team-member-image">
                <img src={image} alt={'member' + name} />
            </div>
            <div className="team-member-name flex justify-content-center align-items">{name}</div>
            <div className="team-member-email flex justify-content-center align-items">
                <a href={`mailto:${emailLink}`}>{email}</a>
            </div>
        </div>
    );
}
