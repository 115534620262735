import "../resources/Header.css";

import React, { useState } from "react";

import { Link } from "react-router-dom";
import Menupoint from "../../Menupoint/components/Menupoint";
import MenupointResponsive from "../../MenupointResponsive/components/MenupointResponsive";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import imgLogo from "../assets/logo.png";
import svgClose from "../assets/close.svg";
import svgMenu from "../assets/menuicon.svg";

interface IHeaderProps {
  activePoint?: "Startseite" | "Team" | "Kontakt" | string;
}

export function Header(props: IHeaderProps) {
  const [menu, setMenu] = useState("none");

  function handleMenuToggle() {
    menu === "none" ? setMenu("block") : setMenu("none");
    if (menu === "none") {
      setMenu("block");
    } else {
      setMenu("none");
    }
  }

  const { activePoint } = props;
  return (
    <>
      <ReactCSSTransitionGroup
        transitionName={{
          enter: "enter",
          enterActive: "enterActive",
          leave: "leave",
          leaveActive: "leaveActive",
          appear: "appear",
          appearActive: "appearActive",
        }}
      >
        <div className="menu-responsive" style={{ display: menu }}>
          <div className="content flex">
            <div className="menu-responsive-header">
              <h1>Menü</h1>
              <div className="menu-responsive-header-close">
                <img src={svgClose} alt="X" onClick={handleMenuToggle} />
              </div>
            </div>
            <div>
              <MenupointResponsive
                title="Startseite"
                route=""
                handleToggle={handleMenuToggle}
              />
              <MenupointResponsive
                title="Team"
                route="Team"
                handleToggle={handleMenuToggle}
              />
              <MenupointResponsive
                title="Kontakt"
                route="Kontakt"
                handleToggle={handleMenuToggle}
              />
            </div>
          </div>
        </div>
      </ReactCSSTransitionGroup>
      <div className="header flex justify-content-space align-items">
        <div className="header-half header-logo">
          <Link to="/">
            <img src={imgLogo} alt="vmb-kraus logo" />
          </Link>
        </div>
        <div className="header-responsive flex justify-content-center align-items">
          <img src={svgMenu} alt="menu" onClick={handleMenuToggle} />
        </div>
        <div className="header-half header-menu flex justify-content-space">
          <Menupoint title="Startseite" route="" activePoint={activePoint} />
          <Menupoint title="Team" route="Team" activePoint={activePoint} />
          <Menupoint
            title="Kontakt"
            route="Kontakt"
            activePoint={activePoint}
          />
        </div>
      </div>
    </>
  );
}

export default Header;
