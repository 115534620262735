import '../resources/Breadcrumb.css';

import React from 'react';


interface IBreadcrumbProps {
  route: string;
}

export function Breadcrumb(props: IBreadcrumbProps) {
  const { route } = props;
  return <div className="breadcrumb">{route}</div>;
}

export default Breadcrumb;
