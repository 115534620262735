import '../resources/MenupointResponsive.css';

import React from 'react';
import { Link } from 'react-router-dom';

interface IMenupointProps {
  title: string;
  route: string;
  handleToggle(): void;
}

export function MenupointResponsive(props: IMenupointProps) {
  const { title, route, handleToggle } = props;
  return (
    <Link to={"/" + route} onClick={handleToggle}>
      <div className="menupoint-responsive">{title}</div>
    </Link>
  );
}

export default MenupointResponsive;
