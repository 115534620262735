import '../resources/InformationFooter.css';

import React from 'react';
import { Link } from 'react-router-dom';


export function InformationFooter() {
  return (
    <div className="information-footer content">
      <h3>Rechtliches</h3>
      <div className="flex row">
        <Link to="/Impressum">Impressum</Link>
        <Link to="/Datenschutz">Datenschutz</Link>
        <Link to="/Erstinformation">Erstinformation</Link>
      </div>
    </div>
  );
}

export default InformationFooter;
